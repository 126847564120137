// init base functions
$(window).on('load', function () {
    scrollDirection();
    initGettersAndSetters();
    changeTouchClickText();
});
$(window).on('hashchange', function (a) {
    initGettersAndSetters();
});

// init AOS
if (is.not.ie()) {
    AOS.init();
} else {
    $('*').removeAttr("data-aos");
}

// l
if ($('.l-nav').length) {
    var isActive = 'is-active';
    var navEl = {
        scrollInit: 0,
        scrollClassScrolled: 99,
        scrollClassMiddle: 600,
        body: $('body'),
        nav: $('.l-nav'),
        navHidden: $('.l-navHidden'),
        hamburguer: $('.l-nav .c-hamburguer'),
        contentHero: $('.l-nav__contentHero'),
        mask: $('.l-navHidden__mask')
    };

    function checkResponsiveMode() {
        var resultCheck;
        var displayCheck = navEl.hamburguer.css('display');
        if (displayCheck !== 'none') {
            resultCheck = 'is-responsive';
            navEl.nav.removeClass('is-desktop');
            navEl.nav.addClass('is-responsive');
        } else {
            resultCheck = 'is-desktop';
            navEl.nav.removeClass('is-responsive');
            navEl.nav.addClass('is-desktop');
        }
        return resultCheck;
    }

    function closeAllNavElements() {
        $('.c-subMenu').removeClass('is-active');
        navEl.hamburguer.removeClass(isActive);
    }
    function disableOverflow() {
        navEl.body.addClass('u-overflowBlocked');
    }
    function enableOverflow() {
        navEl.body.removeClass('u-overflowBlocked');
    }


    // RESPONSIVE MENU
    // - open
    navEl.hamburguer.on('click', function() {
        navEl.navHidden.addClass(isActive);
        disableOverflow();
    });
    navEl.mask.on('click', function() {
        navEl.navHidden.removeClass(isActive);
        navEl.hamburguer.removeClass(isActive);
        enableOverflow();
    });


    // SCROLL CHANGES
    $(window).on('load', function(event) {
        checkResponsiveMode();
        var scrollBody = $(this).scrollTop();
        if (scrollBody > 1) {
            navEl.nav.addClass('is-scrolled');
        } else {
            navEl.nav.removeClass('is-scrolled');
        }
    });
    $(window).on('scroll', function() {
        var scrollBody = $(this).scrollTop();
        // scroll up to 99
        if (scrollBody > navEl.scrollClassScrolled) {
            navEl.nav.addClass('is-scrolled');
        } else {
            navEl.nav.removeClass('is-scrolled');
        }
        // middle class
        if (scrollBody > navEl.scrollClassMiddle) {
            navEl.nav.addClass('is-hidden');
            navEl.nav.addClass('is-middle');
        } else {
            navEl.nav.removeClass('is-hidden');
            navEl.nav.removeClass('is-middle');
        }
        // scroll up or down
        if (scrollBody < navEl.scrollInit) {
            navEl.nav.removeClass('is-hidden');
            navEl.nav.addClass('is-scrolledUp');
            navEl.nav.removeClass('is-scrolledDown');
        } else {
            navEl.nav.removeClass('is-scrolledUp');
            navEl.nav.addClass('is-scrolledDown');
        }
        // close menus on hidden nav
        if(navEl.nav.hasClass('is-hidden')) {
            closeAllNavElements();
        }
        // reference var
        navEl.scrollInit = scrollBody;
    });

}
if ($('.l-headerHome').length) {
    $('.js-scrollContent').on('click', function(e) {
        e.preventDefault();
        var finalDestiny = 'main';
        goToSection__scroll(finalDestiny, 70, 70, 800, 5);
    });
}
if ($('.l-fixedSocial').length) {
    $(window).on('scroll', function () {stickyPosition('.l-fixedSocial', 10, get__navHeight() + 10);});
    $(window).on('load', function () {stickyPosition('.l-fixedSocial', 10, get__navHeight() + 10);});
}

// s
if ($('.s-callStructure').length) {
    $(window).on('load', function() {
        setTimeout(function () {
            var swiper__headerHome = new Swiper('.s-callStructure__swiper > .swiper-container', {
                speed: 400,
                spaceBetween: 0,
                loop: true,
                navigation: {
                    nextEl: '.s-callStructure__swiper .swiper-button-next',
                    prevEl: '.s-callStructure__swiper .swiper-button-prev',
                },
                effect: 'cube',
                cubeEffect: {
                    slideShadows: true,
                    shadow: true,
                    shadowOffset: 10,
                    shadowScale: 0.65,
                },
            });
        }, 50);

    });
}
if ($('.s-installations').length) {
    $(window).on('load', function() {
        setTimeout(function () {
            var swiper__headerHome = new Swiper('.s-installations__swiper > .swiper-container', {
                speed: 400,
                spaceBetween: 0,
                loop: true,
                autoHeight: true,
                navigation: {
                    nextEl: '.s-installations__swiper .swiper-button-next',
                    prevEl: '.s-installations__swiper .swiper-button-prev',
                }
            });
        }, 50);
    });
}
if ($('.s-benefits').length) {
    $(window).on('load', function() {
        setTimeout(function () {
            var swiper__headerHome = new Swiper('.s-benefits__swiper > .swiper-container', {
                speed: 400,
                spaceBetween: 0,
                slidesPerView: 4,
                loop: true,
                navigation: {
                    nextEl: '.s-benefits__swiper .swiper-button-next',
                    prevEl: '.s-benefits__swiper .swiper-button-prev',
                },
                breakpoints: {
                    630: {
                        slidesPerView: 1,
                    },
                    830: {
                        slidesPerView: 2,
                    },
                    1130: {
                        slidesPerView: 3,
                    }
                }
            });
        }, 50);
    });
}
if ($('.s-extraInfos').length) {
    $(window).on('load', function() {
        setTimeout(function () {
            var swiper__headerHome = new Swiper('.s-extraInfos__swiper > .swiper-container', {
                speed: 400,
                spaceBetween: 0,
                slidesPerView: 2,
                navigation: {
                    nextEl: '.s-extraInfos__swiper .swiper-button-next',
                    prevEl: '.s-extraInfos__swiper .swiper-button-prev',
                },
                breakpoints: {
                    1030: {
                        slidesPerView: 1,
                    }
                }
            });
        }, 50);
    });
}
if ($('.s-beVoluntary').length) {
    var swiper__thumbs = new Swiper('.swiper__thumbs', {
        spaceBetween: 0,
        slidesPerView: 7,
        loop: true,
        freeMode: true,
        loopedSlides: 5, //looped slides should be the same
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: '.swiper__thumbs .swiper-button-next',
            prevEl: '.swiper__thumbs .swiper-button-prev',
        },
        breakpoints: {
            350: {
                slidesPerView: 2,
            },
            410: {
                slidesPerView: 3,
            },
            600: {
                slidesPerView: 4,
            },
            760: {
                slidesPerView: 3,
            },
            830: {
                slidesPerView: 4,
            },
            1230: {
                slidesPerView: 5,
            }
        }
    });
    var swiper__big = new Swiper('.swiper__big', {
        spaceBetween: 0,
        loop: true,
        autoHeight: true,
        loopedSlides: 5, //looped slides should be the same
        thumbs: {
            swiper: swiper__thumbs,
        },
    });
}
if ($('.s-contact').length) {
    $('.js-selectEstado').on('change', function() {
       let thisSelectedOption = $(this).val();
       console.log(thisSelectedOption)
       if ((thisSelectedOption === '') || (thisSelectedOption === 'Selecione')) {
           $('.js-selectCidade').closest('li').addClass('u-dnone');
       } else {
           $('.js-selectCidade').closest('li').removeClass('u-dnone');
       }
    });
}

// m
if ($('.m-defaultModal').length) {
    // open
    $('.js-openModal').on('click', function(e) {
        e.preventDefault;
        let thisModal = $(this).attr('href');
        $(thisModal).addClass('is-active');
        $('body').addClass('u-overflowBlocked');
    });

    // close
    $('.js-closeModal').on('click', function(e) {
        e.preventDefault;
        $('.m-defaultModal').removeClass('is-active');
        $('body').removeClass('u-overflowBlocked');
        window.location.hash = '#_';
    });

    // open by hash
    $(window).on('load hashchange', function() {
        if (window.location.hash) {
            if (($(window.location.hash).length) && ($(window.location.hash).hasClass('m-defaultModal'))) {
                $('body').addClass('u-overflowBlocked');
                $(window.location.hash).addClass('is-active');
            } else {
                $('body').removeClass('u-overflowBlocked');
                $(window.location.hash).removeClass('is-active');
            }
        }
    });
}
// COMPONENTS PAGE
if ($('.s-listComponents').length) {
    var cptPage = {
        component__content: $('.c-component__content')
    };

    // sticky sidebar
    $(window).on('scroll', function () {stickyPosition('.s-listComponents__aside', 10, get__navHeight() + 10);});
    $(window).on('load', function () {stickyPosition('.s-listComponents__aside', 10, get__navHeight() + 10);});

    // aside scroll links
    $('.js-asideScrollLink').on('click', function(e) {
        e.preventDefault();
        var finalDestiny = $(this).attr('href');
        goToSection__scroll(finalDestiny, 90, 90, 700, 10);
    });

    // bg components - toggle menu
    $('.js-bgDark').on('click', function() {
        cptPage.component__content.addClass('u-bg-darkGray');
        cptPage.component__content.removeClass('u-bg-white');
        cptPage.component__content.removeClass('u-bg-white3');
    });
    $('.js-bgLight').on('click', function() {
        cptPage.component__content.removeClass('u-bg-darkGray');
        cptPage.component__content.addClass('u-bg-white');
        cptPage.component__content.removeClass('u-bg-white3');
    });
    $('.js-bgNone').on('click', function() {
        cptPage.component__content.removeClass('u-bg-darkGray');
        cptPage.component__content.removeClass('u-bg-white');
        cptPage.component__content.addClass('u-bg-white3');
    });

    // show observations icon
    $('.c-component__observations').each(function() {
        var thisText = $(this).html();
        if (thisText !== '') {
            $(this).closest('.c-component').find('.option__obs').removeClass('u-dnone');
        }
    });

    // show observations on click
    $('.js-openObs').on('click', function() {
       $(this).closest('.c-component').toggleClass('is-obs-active');
    });

    // copy html
    $('.js-copyCode').on('click', function () {
        // get the html
        var thisHTML = $(this)
            .closest('.c-component')
            .find('.c-component__hero')
            .html()
            .replace(/\s+/g, ' ')
            .trim();

        // analisa o codigo copiado e retira os SVG's de dentro
        var replaceSVG = '<?php include(\'media/img/icons/icon_ICONNAME.svg\'); ?>';
        var regexRemoveSVG = /<svg .*?<\/svg>/gi; //non-greedy
        var finalString = thisHTML.replace(regexRemoveSVG, replaceSVG);

        // set the html in val input
        var thisInput = $(this).closest('.c-component').find('.c-component__inputHidden');
        thisInput.val(finalString);
        thisInput.select();
        document.execCommand("copy");
    });
}

// forms
// -- masks
if (is.not.ie()) {
    $('.cepMask').mask("99999-999");
    $('.cpfMask').mask("999.999.999-99");
    $('.dataMask').mask("99/99/9999");
    $('.foneMask').focusout(function () {
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if (phone.length > 10) {a
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');
}
// -- select 2
$('.js-select2__multiple').select2({
    placeholder: "",
});
$('.js-select2__oneOption').select2({
    placeholder: "",
});
$('.c-form__field.--select2 label *').on('click', function () {
    var idSelect2 = $(this).closest('li').find('select').attr('id');
    idSelect2 = '#' + idSelect2;
    $(idSelect2).select2('open');
});
// -- default form
if ($('.c-form').length) {
    // select
    $('.c-form__field.--select select').on('click', function () {
        $(this).closest('.c-form__field.--select').addClass('is-active');
    });
    $('.c-form__field.--select select').on('focusout', function () {
        var thisSelectOption = $(this).children('option:selected').text();
        $(this).closest('.c-form__field.--select').find('.inputBox').children('input').val(thisSelectOption);
        $(this).closest('.c-form__field.--select').removeClass('is-active');
    });

    // file
    $('.inputAttach').on('change', function() {
        var t = $(this).val().split('fakepath');
        console.log(t);
        $(this).closest('.liFile').find('.inputText').val(t[1]);
    });

    // rangeDefault
    var rangeSlider = function(){
        var slider = $('.rangeSlider'),
            range = $('.rangeSlider__range'),
            value = $('.rangeSlider__value');

        slider.each(function(){
            value.each(function(){
                var value = $(this).prev().attr('value');
                $(this).html(value);
            });
            range.on('input', function(){
                $(this).next(value).html(this.value);
            });
        });
    };
    rangeSlider();
}

// grids / tabs
if ($('.c-responsiveGrid').length) {

    function removeGridClass(el) {
        for (var i = 1; i <= 12; i++) {
            el.removeClass('--col' + i);
        }
        el.removeClass('--colNaN');
    }

    $(window).on('load resize', function() {
        $('.c-responsiveGrid').each(function( index ) {
            var propsGrid = {
                maxwBreaks: $(this).attr('data-maxw-breaks').split(', ').map(Number),
                maxwCols: $(this).attr('data-maxw-cols').split(', ').map(Number),
                initCols: parseInt($(this).attr('data-cols-init')),
                windowW: $(window).width(),
            };
            var qtfBreaks = propsGrid.maxwBreaks.length;
            if (propsGrid.maxwBreaks.length === propsGrid.maxwCols.length) {
                for (var i = 0; i <= qtfBreaks; i++) {
                    if (propsGrid.maxwBreaks[i] >= propsGrid.windowW) {
                        removeGridClass($(this));
                        $(this).addClass('--col' + propsGrid.maxwCols[i]);
                    }
                    if (propsGrid.windowW > propsGrid.maxwBreaks[0]) {
                        removeGridClass($(this));
                        $(this).addClass('--col' + propsGrid.initCols);
                    }
                }
            } else {
                console.log('Ta faltando media querie ou colunas, verifique seu componente');
            }
        });
    });
}
if ($('.c-tabHorizontal').length) {
    var propsTabH = {
        el: $('.c-tabHorizontal'),
        item: $('.c-tabHorizontal a'),
        header: $('.c-tabHorizontal__header'),
        content: $('.c-tabHorizontal__content'),
    };

    propsTabH.item.on('click', function(e) {
        e.preventDefault();
        // toggle active header
        $(this).closest('.c-tabHorizontal__header').find('a').removeClass('is-active');
        $(this).addClass('is-active');

        // scroll to active
        //console.log($(this).closest('.c-tabHorizontal__header ul').scrollLeft());

        // toggle active content
        var contentDestiny = $(this).attr('href');
        var thisElement = $(this);
        thisElement.closest(propsTabH.el).find(propsTabH.content).removeClass('is-active');
        setTimeout(function(){
            thisElement.closest(propsTabH.el).find(propsTabH.content).addClass('u-dnone');
            $(contentDestiny).removeClass('u-dnone');
            $(contentDestiny).addClass('is-active');
        }, 350);
    });
}
if ($('.c-toggleList').length) {
    $('.c-toggleList__header').on('click', function() {
        $(this).parent().toggleClass('is-active');
    });
}

// menus
if ($('.c-subMenu').length) {
    var propsSubMenu = {
        toggle: $('.c-subMenu__call'),
        content: $('.c-subMenu__content'),
        el: $('.c-subMenu'),
        primary: $('.js-subMenuPrimary'),
        mask: $('.c-subMenu__mask')
    };

    function removeMask() {
        propsSubMenu.mask.removeClass('is-active');
    }

    // close
    propsSubMenu.mask.on('click', function() {
        if ($(this).closest('.c-subMenu').hasClass('--mobile')) {
            console.log('mobile')
        } else {
            propsSubMenu.el.removeClass('is-active');
            removeMask();
        }
    });
    propsSubMenu.primary.parent().children('.c-subMenu__content').on('mouseleave', function() {
        if ($(this).closest('.c-subMenu').hasClass('--mobile')) {
            console.log('mobile')
        } else {
            propsSubMenu.el.removeClass('is-active');
            removeMask();
        }
    });

    // open
    propsSubMenu.primary.on('click', function(e) {
        e.preventDefault();
        if ($(this).parent('.c-subMenu').hasClass('--mobile')) {
            $(this).parent('.c-subMenu').toggleClass('is-active');
        } else {
            if ($(this).parent('.c-subMenu').hasClass('is-active')) {
                $(this).parent('.c-subMenu').removeClass('is-active');
            } else {
                if (($(this).parents('.l-nav').length) && ($('.l-nav').hasClass('is-responsive'))) {
                    console.log('l-nav')
                    $(this).parent('.c-subMenu').toggleClass('is-active');
                } else {
                    console.log('not nav')
                    $('.c-subMenu').removeClass('is-active');
                    $(this).parent('.c-subMenu').addClass('is-active');
                }
            }
        }
    });
    propsSubMenu.toggle.on('click', function(e) {
        e.preventDefault();
        if (!$(this).hasClass('js-subMenuPrimary')) {
            $(this).parent('.c-subMenu').toggleClass('is-active');
        }
    });
}
if ($('.c-subMenuHover').length) {
    function mobileAdjusts() {
        if (is.mobile()) {
            $('.c-subMenuHover').children('a').addClass('is-blocked-mobile');
            $('.c-subMenuHover').addClass('is-mobile');
        } else {
            $('.c-subMenuHover').children('a').removeClass('is-blocked-mobile');
            $('.c-subMenuHover').removeClass('is-mobile');
        }
    }
    $(window).on('load', function(){mobileAdjusts()});
    $(window).on('resize', function(){mobileAdjusts()});
}
if ($('.c-hamburguer').length) {
    $('.c-hamburguer').on('click', function() {
       $(this).toggleClass('is-active');
    });
}

// ui
if ($('.c-searchListMedic').length) {
    $('.js-especialidadesMedicas').on('change', function() {
        let thisSelectedMedic = $(this).val();
        console.log(thisSelectedMedic);

        if (thisSelectedMedic === 'todos') {
            $('.c-cardMedic').closest('li').removeClass('u-dnone');
        } else {
            $('.c-cardMedic').closest('li').addClass('u-dnone');
            $('.c-cardMedic[data-medic-select-value='+thisSelectedMedic+']').closest('li').removeClass('u-dnone');
        }
    });
}
















